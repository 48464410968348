import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Checkbox,
  debounce,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  Popover,
  Stack,
  styled,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { SystemStyleObject } from '@mui/system';
import { DragEvent, useEffect, useMemo, useState } from 'react';
import { Extension, ExtensionTaskType, ListExtensionRequest, listExtensions, VersionSummary } from '../../services/extensions';
import { AddNode } from '../../services/project';
import { NodeDropData, TaskDropData } from './utils';

const drawerWidth = 220;

const openedMixin = (theme: Theme): SystemStyleObject<Theme> => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): SystemStyleObject<Theme> => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[6],
    fontSize: 11,
  },
}));
const ExtensionImage = styled('img')({
  maxWidth: 24,
  maxHeight: 24,
});

type TooltipContentProps = {
  name: string;
  description: string;
  link: string
}

function TooltipContent({ name, description, link }: TooltipContentProps) {
  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="subtitle2">
        {name}
      </Typography>
      <Typography sx={{ mb: 2 }}>
        {description}
      </Typography>
      <Link href={link}>Learn more here</Link>
    </Box>
  );
}

function QualifiedId(ext: Extension) {
  return `${ext.getId()}${ext.getLatestVersion()?.getVersion() ?? ''}`;
}

type FlowItem = {
  icon: JSX.Element;
  name: string;
  tooltip: JSX.Element;
  tasks: TaskDropData[];
};

const flowItems: FlowItem[] = [
  {
    icon: (<PlayCircleFilledWhiteIcon color="primary" />),
    name: 'Start',
    tooltip: <TooltipContent name="Start"
      description="This is the starting point of a graph. Only one may be used per graph"
      link="#" />,
    tasks: [
      {
        name: 'Input',
        description: 'The input node defines the input to a subgraph',
        type: AddNode.NodeType.INPUTTASK,
      },
    ] as TaskDropData[],
  },
];

type ExtensionLogoProps = {
  logo: string;
}

export function ExtensionLogo({ logo }: ExtensionLogoProps) {
  return (
    <Box sx={{
      position: 'relative',
      textAlign: 'center',
      width: 24,
      height: 24,
    }}>
      <ExtensionImage src={logo} alt="Extension logotype" />
    </Box>);
}

interface FilterProps {
  flows: boolean;
  widgets: boolean;
  functions: boolean;
}

type TasksDrawerProps = {
  disableDrag: boolean,
  disableStart: boolean,
}

export function TasksDrawer({ disableDrag, disableStart }: TasksDrawerProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [filter, setFilter] = useState<FilterProps>({
    flows: true,
    functions: true,
    widgets: true,
  });
  const [search, setSearch] = useState<string>('');
  const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null);
  const [extensions, setExtensions] = useState<Extension[]>([]);
  const [tooltipOpen, setTooltipOpen] = useState<number | string>('');

  const debounceHandler = useMemo(() => debounce(async (search: string, widgets: boolean, functions: boolean) => {
    const request = new ListExtensionRequest()
      .setPageSize(500)
      .setPageNumber(0)
      .setOrderBy(ListExtensionRequest.Order.NAME)
      .setQuery(search)
      .setOptions(new ListExtensionRequest.ResponseOptions().setIncludeTasks(true));
    const types: ExtensionTaskType[] = [];
    if (widgets) {
      types.push(ExtensionTaskType.WIDGET);
    }
    if (functions) {
      types.push(ExtensionTaskType.FUNCTION);
    }
    // TODO: Actually do something with the task types?
    //       Or maybe just delete the feature.
    if (widgets || functions) {
      setLoading(true);
      try {
        const result = await listExtensions(request);
        const extensions = result.getItemsList()
          .filter((ext) => ext.getLatestVersion() !== undefined);
        setExtensions(extensions);
      } finally {
        setLoading(false);
      }
    } else {
      setExtensions([]);
    }
  }, 200), []);

  useEffect(() => {
    debounceHandler(search, filter.widgets, filter.functions);
    return () => {
      debounceHandler.clear();
    };
  }, [debounceHandler, filter, search]);

  function toggleOpen() {
    setOpen(!open);
  }

  function handleDragStart(event: DragEvent<HTMLLIElement>, data: NodeDropData) {
    event.dataTransfer.setData('application/dibric-graph-editor', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  }

  const filteredFlows = flowItems.filter(((item) => item.name.toLowerCase().includes(search.toLowerCase())));

  return (
    <Paper
      sx={(theme) => ({
        width: drawerWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 10,
        boxSizing: 'border-box',
        overflowX: 'hidden',
        ...(open ? openedMixin(theme) : closedMixin(theme)),
      })}
      elevation={6}
    >
      <LinearProgress
        color="primary"
        style={{ height: 3, visibility: loading ? 'visible' : 'hidden' }}
        aria-label="Loading extensions"
      />
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: open ? 'flex-end' : 'center',
      }}>
        <IconButton onClick={toggleOpen} title="Minimize drawer">
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
      <Stack direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ mb: open ? 2 : 0 }}>
        {open && (<TextField
          sx={{ ml: 2 }}
          label="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />)}
        <Fab
          color="primary"
          size="small"
          sx={{ mx: 2, mb: 2, mt: 1 }}
          onClick={(event) => setFilterAnchor(event.currentTarget)}
          variant="extended"
          title="Filter tasks"
        >
          <FilterListIcon />
        </Fab>
        <Popover
          id="filter-popover"
          open={Boolean(filterAnchor)}
          onClose={() => setFilterAnchor(null)}
          anchorEl={filterAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Filter
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filter.flows}
                    onChange={(event) => setFilter({
                      ...filter,
                      flows: event.target.checked,
                    })}
                  />
                )}
                label="Flows"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filter.widgets}
                    onChange={
                      (event) => setFilter({
                        ...filter,
                        widgets: event.target.checked,
                      })}
                  />
                )}
                label="Widgets"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filter.functions}
                    onChange={
                      (event) => setFilter({
                        ...filter,
                        functions: event.target.checked,
                      })
                    }
                  />
                )}
                label="Functions"
              />
            </Grid>
          </Grid>
        </Popover>
      </Stack>
      {filter.flows && filteredFlows.length > 0 && (<Divider />)}
      <Box sx={{
        flex: 1,
        pt: 2,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
        {filter.flows && (
          <List>
            {filteredFlows.map((item, index) => {
              // TODO: We need to restructure this
              const disableTask = disableDrag || (index === 0 && disableStart);
              return (
                <LightTooltip
                  key={index}
                  placement="right-end"
                  onClose={() => setTooltipOpen('')}
                  open={index === tooltipOpen}
                  title={item.tooltip}>
                  <ListItem
                    disablePadding
                    draggable={!disableTask}
                    onDragStart={(event) => handleDragStart(event, {
                      name: item.name,
                      tasks: item.tasks,
                    } as NodeDropData)}
                    onClick={() => setTooltipOpen('')}
                    onContextMenu={(event) => {
                      event.preventDefault();
                      setTooltipOpen(tooltipOpen ? '' : index);
                    }}
                    sx={{
                      display: 'block',
                      cursor: disableTask ? 'unset' : 'grab',
                      opacity: disableTask ? 0.4 : 1,
                    }}>
                    <Box
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        display: 'flex',
                        flexDirection: 'row',
                      }}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} primaryTypographyProps={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                    </Box>
                  </ListItem>
                </LightTooltip>
              );
            })}
          </List>
        )}
        {extensions.length > 0 && (<Divider />)}
        <List>
          {extensions.map((extension) => {
            const version = extension.getLatestVersion() ?? new VersionSummary();
            return (
              <LightTooltip
                key={QualifiedId(extension)}
                placement="right-end"
                onClose={() => setTooltipOpen('')}
                open={QualifiedId(extension) === tooltipOpen}
                title={(
                  <Stack>
                    <Stack direction="row" alignItems="flex-start">
                      <div style={{ flex: 1 }}>
                        <Typography variant="subtitle2">{version.getLabel()}</Typography>
                        <Typography
                          variant="caption">{version.getVersion()}</Typography>
                      </div>
                    </Stack>
                    <Typography variant="body2">{version.getDescription()}</Typography>
                    {version.getDeprecated() && (
                      <Typography variant="body2" color="secondary"
                        style={{ alignSelf: 'flex-end' }}>Deprecated</Typography>
                    )}
                  </Stack>
                )}>
                <ListItem
                  key={QualifiedId(extension)}
                  draggable={!disableDrag}
                  onDragStart={(event) => handleDragStart(event, {
                    name: version.getLabel(),
                    tasks: version.getTasksList().map((task) => ({
                      name: task.getLabel(),
                      description: task.getDescription(),
                      type: AddNode.NodeType.EXTENSIONTASK,
                      logo: task.getLogo() ?? version.getLogo(),
                      extensionType: task.getType(),
                      extensionId: extension.getId(),
                      extensionVersion: version.getVersion(),
                      extensionTaskId: task.getId(),
                    })),
                  } as NodeDropData)}
                  disablePadding
                  onClick={() => setTooltipOpen('')}
                  onContextMenu={(event) => {
                    event.preventDefault();
                    setTooltipOpen(tooltipOpen ? '' : QualifiedId(extension));
                  }}
                  sx={{
                    display: 'block',
                    opacity: disableDrag ? 0.4 : 1,
                    cursor: disableDrag ? 'unset' : 'grab',
                  }}>
                  <Box
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}>
                      <ExtensionLogo logo={version.getLogo()} />
                    </ListItemIcon>
                    <ListItemText primary={version.getLabel()} sx={{ opacity: open ? 1 : 0 }} primaryTypographyProps={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                    {version.getDeprecated() && (
                      <ListItemSecondaryAction style={{ marginTop: -5 }}>
                        <WarningIcon color="warning" fontSize="small" />
                      </ListItemSecondaryAction>
                    )}
                  </Box>
                </ListItem>
              </LightTooltip>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
}
